import moment from 'moment'


jQuery(document).ready(function ($) {

  // MOBILE MENU
  $('.mobile-menu-icon').on('click', function () {
    $("#mobile-nav-bg").toggleClass('active')
    $(".mobile-layout-wrapper").toggleClass('active')
    $(".line").toggleClass('active')
  })

  $('.nav-button').on('click', function () {
    if ($('.mobile-layout-wrapper').hasClass('active')) {
      $("#mobile-nav-bg").toggleClass('active')
      $(".mobile-layout-wrapper").toggleClass('active')
      $(".line").toggleClass('active')
    }
  })

  function addSmoothScrolling() {
    $('a[href^="#"]').on('click', function (e) {
      e.preventDefault();
    
      var targetId = $(this).attr("href");
      $('html, body').animate({ scrollTop: $(targetId).offset().top }, 'slow');
    });
  }
  
  addSmoothScrolling();

});